.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.glow-container {
  --spread: 60;
}

.glow-card {
  --active: 0.15;
  --start: 0;
}

.glow-card:is(:hover, :focus-visible) {
  z-index: 2;
}

.glows {
  pointer-events: none;
  position: absolute;
  inset: 0;
  filter: blur(calc(var(--blur) * 1px));
}

.glows::after,
.glows::before {
  --alpha: 0;
  content: "";
  background: conic-gradient(from 180deg at 50% 70%,
      #f626af 0deg,
      #8228ec 72.0000010728836deg,
      #f626af 144.0000021457672deg,
      #0025ba 216.00000858306885deg,
      #f626af 288.0000042915344deg,
      #fafafa 1turn);
  background-attachment: fixed;
  position: absolute;
  inset: -5px;
  border: 8px solid transparent;
  border-radius: 12px;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(from calc((var(--start) - (var(--spread) * 0.5)) * 1deg),
      #000 0deg,
      #fff,
      #0000 calc(var(--spread) * 1deg));
  mask-composite: intersect;
  mask-clip: padding-box, border-box;
  opacity: var(--active);
  transition: opacity 1s;
}

.glow-card::before {
  position: absolute;
  inset: 0;
  border: 2px solid transparent;
  content: "";
  border-radius: 12px;
  pointer-events: none;
  background: #84738c;
  background-attachment: fixed;
  border-radius: 12px;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) * 1deg),
      #ffffff26 0deg,
      white,
      #ffffff26 calc(var(--spread) * 2.5deg));
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  opacity: var(--active);
  transition: opacity 1s;
}

.glow-card::after {
  --bg-size: 100%;
  content: "";
  pointer-events: none;
  position: absolute;
  background: conic-gradient(from 180deg at 50% 70%,
      #f626af 0deg,
      #8228ec 72.0000010728836deg,
      #f626af 144.0000021457672deg,
      #0025ba 216.00000858306885deg,
      #f626af 288.0000042915344deg,
      #fafafa 1turn);
  background-attachment: fixed;
  border-radius: 12px;
  opacity: var(--active, 0);
  transition: opacity 1s;
  --alpha: 0;
  inset: 0;
  border: 2px solid transparent;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) * 1deg),
      #0000 0deg,
      #fff,
      #0000 calc(var(--spread) * 0.5deg));
  filter: brightness(1.5);
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
}