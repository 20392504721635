@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

:root {
  --foreground-rgb: rgb(211, 216, 232);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background-color: #0d1224;
}

#sticky-card-1 {
  --index: 1;
}

#sticky-card-2 {
  --index: 2;
}

#sticky-card-3 {
  --index: 3;
}

#sticky-card-4 {
  --index: 4;
}

.sticky-card {
  top: calc(var(--index) * 4rem);
}